import { createRouter, createWebHashHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'
import store from '../store'

const routes = [
  {
    path: '/',
    component: () => import('@/views/pages/Login'),
    name: 'Home',
  },
  {
    path: '/login',
    component: () => import('@/views/pages/Login'),
    name: 'Login',
  },
  {
    path: '/register',
    component: () => import('@/views/pages/Register'),
    name: 'Register',
  },
  {
    path: '/dashboard',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',

    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        meta: {
          requiresAuth: true,
          title: 'Dashboard',
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },

      {
        path: '/users',
        name: 'Users',
        meta: {
          requiresAuth: true,
          title: 'Users',
        },
        component: () => import('@/views/pages/users/Index.vue'),
      },

      {
        path: '/users/:id',
        name: 'user-info',
        component: () => import('@/views/pages/users/Show.vue'),
        meta: {
          requiresAuth: true,
          title: 'User Info',
        },
      },

      {
        path: '/edit-user/:id',
        name: 'edit-user',
        component: () => import('@/views/pages/users/Edit.vue'),
        meta: {
          requiresAuth: true,
          title: 'Edit User Info',
        },
      },

      {
        path: '/savings',
        name: 'Savings',
        meta: {
          requiresAuth: true,
          title: 'Savings',
        },
        component: () => import('@/views/pages/savings/Index.vue'),
      },

      {
        path: '/savings/:id',
        name: 'saving-info',
        meta: {
          requiresAuth: true,
          title: 'Savings Info',
        },
        component: () => import('@/views/pages/savings/Show.vue'),
      },
      {
        path: '/bills',
        name: 'bills',
        meta: {
          requiresAuth: true,
          title: 'Bills',
        },
        component: () => import('@/views/pages/bills/Index.vue'),
      },
      {
        path: '/deposits',
        name: 'Deposits',
        meta: {
          requiresAuth: true,
          title: 'Deposits',
        },
        component: () => import('@/views/pages/deposits/Index.vue'),
      },
      {
        path: '/withdrawals',
        name: 'Withdrawals',
        meta: {
          requiresAuth: true,
          title: 'Withdrawals',
        },
        component: () => import('@/views/pages/withdrawals/Index.vue'),
      },
      {
        path: '/transactions',
        name: 'transactions',
        meta: {
          requiresAuth: true,
          title: 'Transactions',
        },
        component: () => import('@/views/pages/transactions/Index.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next({ name: 'Login' })
  } else {
    next()
  }
})

export default router
