export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
    // badge: {
    //   color: 'primary',
    //   text: 'NEW',
    // },
  },
  {
    component: 'CNavItem',
    name: 'Users',
    to: '/users',
    icon: 'cil-notes',
  },
  {
    component: 'CNavItem',
    name: 'Savings',
    to: '/savings',
    icon: 'cil-notes',
  },
  {
    component: 'CNavItem',
    name: 'Transactions',
    to: '/transactions/',
    icon: 'cil-notes',
  },
  {
    component: 'CNavItem',
    name: 'Bills',
    to: '/bills',
    icon: 'cil-notes',
  },
  {
    component: 'CNavItem',
    name: 'Deposits',
    to: '/deposits/',
    icon: 'cil-notes',
  },
  {
    component: 'CNavItem',
    name: 'Withdrawal',
    to: '/withdrawals/',
    icon: 'cil-notes',
  },
  {
    component: 'CNavTitle',
    name: 'Account',
  },

  {
    component: 'CNavItem',
    name: 'Logout',
    to: '/logout',
    icon: 'cil-cursor',
  },
  // {
  //   component: 'CNavItem',
  //   name: 'Categories',
  //   to: '/categories',
  //   icon: 'cil-notes',
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Cover Images',
  //   to: '/cover',
  //   icon: 'cil-chart-pie',
  // },
]
