import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsExample from '@/components/DocsExample'
//import VeeValidate from 'vee-validate'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

import Vue3FormWizard from 'vue3-form-wizard'
import 'vue3-form-wizard/dist/style.css'
import { createPinia } from 'pinia'
import Notifications from '@kyvg/vue3-notification'
import paystack from 'vue-paystack'
import moment from 'moment'
var numeral = require('numeral')

import Pagination from 'v-pagination-3'

const pinia = createPinia()

const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)
//app.use(VeeValidate)
app.use(VueGoogleAutocomplete)
app.use(Vue3FormWizard)
app.use(pinia)
app.use(Notifications)
app.use(paystack)
// app.use(moment)
app.use(Pagination)
app.component('VueDatePicker', VueDatePicker)

app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('VueGoogleAutocomplete', VueGoogleAutocomplete)
app.component('Vue3FormWizard', Vue3FormWizard)
app.component('pagination', Pagination)
app.component('DocsExample', DocsExample)

app.config.globalProperties.$filters = {
  formatDate(value) {
    return moment(String(value)).format('DD/MM/YYYY')
  },

  formatNumber(value) {
    return numeral(value).format('0,0')
  },
}
app.mount('#app')
