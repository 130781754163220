<template>
  <CFooter>
    <div>
      <a href="https://chequemate.app" target="_blank">Chequemate</a>
      <span class="ms-1"
        >&copy; {{ new Date().getFullYear() }} Chequemate.</span
      >
    </div>
    <div class="ms-auto">
      <span class="me-1" target="_blank">Powered by</span>
      <a href="https://chequemate.app/">Chequemate</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
