<template>
  <notifications position="top right" />

  <router-view />
</template>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>
