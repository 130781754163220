export const logo = [
  '556 134',
  `<svg width="500" height="100" viewBox="0 0 250 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M42.7285 19.6944C42.7285 12.6805 47.9395 7.63672 54.8199 7.63672C60.8992 7.63672 64.6731 11.4443 65.8085 15.2855L60.464 17.4564C59.7628 14.7179 57.8927 12.914 54.6854 12.914C50.9777 12.914 48.4063 15.6198 48.4063 19.6944C48.4063 23.7691 50.9786 26.4413 54.6527 26.4413C57.8927 26.4413 59.7628 24.6374 60.464 21.8989L65.8085 24.0697C64.6059 28.0445 60.7983 31.7186 54.7526 31.7186C47.9385 31.7186 42.7285 26.6747 42.7285 19.6944Z" fill="#FCFCFC"/>
<path d="M84.9823 20.0954V31.2847H79.4044V21.1971C79.4044 19.2933 78.1019 18.1906 76.4315 18.1906C74.7611 18.1906 73.3923 19.3932 73.3923 20.9963V31.2838H67.8145V6.86768H73.3923V14.8172C74.5949 13.8489 76.1981 13.2476 78.1355 13.2476C82.2774 13.2476 84.9832 16.0534 84.9832 20.0954H84.9823Z" fill="#FCFCFC"/>
<path d="M96.8722 27.1762C98.8433 27.1762 99.8115 26.5086 100.513 25.3387L104.688 28.1108C103.285 29.9474 101.08 31.7176 96.7051 31.7176C90.8928 31.7176 87.2188 27.7429 87.2188 22.4993C87.2188 17.2557 91.2934 13.2473 96.3708 13.2473C102.116 13.2473 105.523 17.7897 105.523 22.1324C105.523 23.0007 105.456 23.6692 105.389 24.1361H92.6305C93.1981 26.1071 94.7677 27.1752 96.8722 27.1752V27.1762ZM100.346 20.6627C99.8778 18.659 98.5417 17.4228 96.5043 17.4228C94.467 17.4228 93.03 18.5255 92.5632 20.6627H100.346Z" fill="#FCFCFC"/>
<path d="M126.165 13.6818V37.2632H120.587V30.1821C119.384 31.1513 117.815 31.7189 115.944 31.7189C110.834 31.7189 107.16 27.7778 107.16 22.5006C107.16 17.2233 110.701 13.2822 115.811 13.2822C117.948 13.2822 119.718 14.017 121.022 15.2859V13.6828H126.166L126.165 13.6818ZM120.82 22.4996C120.82 19.9273 119.183 18.158 116.745 18.158C114.308 18.158 112.703 19.9955 112.703 22.4996C112.703 25.0038 114.407 26.8086 116.745 26.8086C119.084 26.8086 120.82 25.072 120.82 22.4996Z" fill="#FCFCFC"/>
<path d="M146.074 13.6821V31.2841H140.863V29.8145C139.626 30.9835 137.89 31.7183 135.952 31.7183C131.444 31.7183 129.172 28.6455 129.172 24.6708V13.6821H134.75V23.936C134.75 25.5727 135.819 26.7753 137.623 26.7753C139.427 26.7753 140.528 25.6063 140.528 23.936V13.6821H146.073H146.074Z" fill="#FCFCFC"/>
<path d="M158.13 27.1762C160.101 27.1762 161.069 26.5086 161.771 25.3387L165.946 28.1108C164.543 29.9474 162.338 31.7176 157.963 31.7176C152.151 31.7176 148.477 27.7429 148.477 22.4993C148.477 17.2557 152.551 13.2473 157.629 13.2473C163.374 13.2473 166.781 17.7897 166.781 22.1324C166.781 23.0007 166.713 23.6692 166.647 24.1361H153.888C154.456 26.1071 156.025 27.1752 158.13 27.1752V27.1762ZM161.604 20.6627C161.137 18.659 159.8 17.4228 157.763 17.4228C155.726 17.4228 154.29 18.5255 153.822 20.6627H161.604Z" fill="#FCFCFC"/>
<path d="M189.728 31.2843L188.593 16.3209L184.117 30.5495H179.474L174.998 16.2537L173.863 31.2843H168.686L170.389 8.0708H176.902L181.779 23.5692L186.689 8.0708H193.169L194.939 31.2843H189.728Z" fill="#FCFCFC"/>
<path d="M215.881 13.6815V31.2835H210.738V29.6803C209.501 30.9828 207.832 31.7177 205.661 31.7177C200.551 31.7177 196.877 27.7765 196.877 22.4993C196.877 17.2221 200.551 13.6143 205.661 13.6143C207.832 13.6143 209.502 14.2818 210.738 15.5181V13.6805H215.881V13.6815ZM210.537 22.4993C210.537 19.927 208.9 18.1576 206.462 18.1576C204.024 18.1576 202.42 19.9952 202.42 22.4993C202.42 25.0034 204.091 26.8083 206.462 26.8083C208.834 26.8083 210.537 25.038 210.537 22.4993Z" fill="#FCFCFC"/>
<path d="M220.76 24.5042V18.425H217.82V13.6818H219.223C220.459 13.6818 221.093 13.0142 221.093 11.7107V9.00488H226.504V13.6808H230.512V18.424H226.304V23.8347C226.304 25.5378 227.206 26.4397 228.775 26.4397H230.613V31.2828H227.606C223.064 31.2828 220.759 28.577 220.759 24.5023L220.76 24.5042Z" fill="#FCFCFC"/>
<path d="M241.335 27.1762C243.306 27.1762 244.274 26.5086 244.976 25.3387L249.151 28.1108C247.748 29.9474 245.543 31.7176 241.168 31.7176C235.356 31.7176 231.682 27.7429 231.682 22.4993C231.682 17.2557 235.756 13.2473 240.834 13.2473C246.579 13.2473 249.986 17.7897 249.986 22.1324C249.986 23.0007 249.919 23.6692 249.852 24.1361H237.093C237.661 26.1071 239.231 27.1752 241.335 27.1752V27.1762ZM244.809 20.6627C244.342 18.659 243.006 17.4228 240.968 17.4228C238.931 17.4228 237.495 18.5255 237.027 20.6627H244.809Z" fill="#FCFCFC"/>
<path d="M23.7481 15.4109H22.2276C21.9116 15.4109 21.6186 15.602 21.5177 15.9027C21.3132 16.5136 20.7359 16.9545 20.0548 16.9545C19.3738 16.9545 18.7965 16.5136 18.5919 15.9027C18.4901 15.602 18.1971 15.4109 17.8811 15.4109H16.3606C15.4298 15.4109 14.6758 16.1659 14.6758 17.0947V20.6843C14.6758 23.7004 17.1444 26.2247 20.1595 26.168C23.0815 26.1123 25.4339 23.7244 25.4339 20.7899V17.0947C25.4339 16.1659 24.6799 15.4109 23.7491 15.4109H23.7481ZM20.4909 21.5007V22.5862C20.4909 22.8273 20.295 23.0223 20.0539 23.0223C19.8128 23.0223 19.6168 22.8273 19.6168 22.5862V21.5007C19.2922 21.3375 19.0684 21.0089 19.0684 20.6218C19.0684 20.0772 19.5092 19.6363 20.0539 19.6363C20.5985 19.6363 21.0394 20.0772 21.0394 20.6218C21.0394 21.0089 20.8146 21.3375 20.4909 21.5007Z" fill="#FCFCFC"/>
<mask id="mask0_1783_31481" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="39" height="40">
<path d="M19.7479 0C29.0911 0 36.1405 5.25899 38.3776 13.7627H27.8597C26.2373 10.4056 23.2721 8.72752 19.6355 8.72752C13.7617 8.72752 9.73417 13.0913 9.73417 20.0283C9.73417 26.9654 13.7617 31.3292 19.6355 31.3292C23.2721 31.3292 26.2363 29.6511 27.8597 26.294H38.3776C36.1396 34.7977 29.0911 40 19.7479 40C8.16752 40 0 31.7768 0 20.0283C0 8.2799 8.16848 0 19.7479 0Z" fill="white"/>
</mask>
<g mask="url(#mask0_1783_31481)">
<path d="M41.096 4.92909C41.096 9.80866 37.1404 13.7642 32.2609 13.7642C27.3813 13.7642 23.4258 9.80866 23.4258 4.92909C23.4258 0.0495214 27.3813 -3.90601 32.2609 -3.90601C37.1404 -3.90601 41.096 0.0495214 41.096 4.92909Z" fill="#FCFCFC"/>
<path d="M41.096 35.2221C41.096 40.1016 37.1404 44.0572 32.2609 44.0572C27.3813 44.0572 23.4258 40.1016 23.4258 35.2221C23.4258 30.3425 27.3813 26.387 32.2609 26.387C37.1404 26.387 41.096 30.3425 41.096 35.2221Z" fill="#FCFCFC"/>
<path d="M20.2698 1.77309C20.2698 6.65266 16.3143 10.6082 11.4347 10.6082C6.55514 10.6082 2.59961 6.65266 2.59961 1.77309C2.59961 -3.10648 6.55514 -7.06201 11.4347 -7.06201C16.3143 -7.06201 20.2698 -3.10648 20.2698 1.77309Z" fill="#FCFCFC"/>
<path d="M20.2698 38.3774C20.2698 43.2579 16.3143 47.2125 11.4347 47.2125C6.55514 47.2125 2.59961 43.2569 2.59961 38.3774C2.59961 33.4978 6.55514 29.5432 11.4347 29.5432C16.3143 29.5432 20.2698 33.4987 20.2698 38.3774Z" fill="#FCFCFC"/>
<path d="M9.81082 19.9984C9.81082 24.878 5.8553 28.8335 0.975724 28.8335C-3.90385 28.8335 -7.85938 24.878 -7.85938 19.9984C-7.85938 15.1189 -3.90385 11.1633 0.975724 11.1633C5.8553 11.1633 9.81082 15.1189 9.81082 19.9984Z" fill="#FCFCFC"/>
</g>
</svg>

`,
]
